/* eslint-disable */
import React from 'react'
import { injectIntl } from 'react-intl'
import { Select, Spin } from 'antd'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { URL } from '../../../../pages/app-config/index'
import { changeSelectedOption, changeAutoSearchBar } from '../../../../redux/menu/actions'
import styles from './style.module.scss'

const { Option, OptGroup } = Select;

const mapStateToProps = ({ user, menu }) => {
  return {
    Token: user.token,
    removeAutoSearchBar: menu.removeAutoSearchBar
  }
}



@connect(mapStateToProps)
@injectIntl
class Search extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuDataForService: [],
      fetching: false,
      defaultValueToSet: 'Search'
    }
  }


  componentWillMount() {

    const newToken = localStorage.getItem('Token')
    const RoleId = localStorage.getItem('roleId')

    const client = new ApolloClient({
      uri: URL,
      request: operation => {
        operation.setContext({
          headers: {
            authorization: `bearer ${newToken}`,
          },
        });
      },
    });

    const GET_MENUS = gql`query { 
      getMenuList(ad_role_id:"${RoleId}")
  }`;

    client
      .query({
        query: GET_MENUS,
      })
      .then(result => {
        this.setState({ menuDataForService: [], fetching: true });
        const getMenu = JSON.parse(result.data.getMenuList);

        const SortMenus = getMenu["children"].sort(function (a, b) {
          return a.seqno - b.seqno
        })

        this.setState({ menuDataForService: SortMenus })


      }).catch(err => {
        const error = JSON.parse(JSON.stringify(err));
        const { message } = error;
        if (message === "Network error: Unexpected token < in JSON at position 0") {
          const { history } = this.props
          history.push('/system/login')
        }
      })
  }

  componentWillReceiveProps = nextProps => {

    if (nextProps.removeAutoSearchBar === true) {
      this.clearAutoSelect();
    }
  }


  toSendRequest = (windowid) => {
    const { dispatch } = this.props;
    if (windowid) {
      dispatch(changeSelectedOption({ selectedOption: windowid }))
      window.localStorage.setItem('adWinId', windowid);
      window.localStorage.setItem('recordId', undefined);
      dispatch(changeAutoSearchBar({ removeAutoSearchBar: false }))
    } else {
      console.log("NO WINDOW")
    }

  }

  toSendRequest1 = (windowid, title) => {
    this.toSendRequest(windowid)
    this.setState({ defaultValueToSet: title })
    window.location = '/#/window/list/'.concat(`${windowid}`)
  
  }


  clearAutoSelect = () => {
    this.setState({ defaultValueToSet: 'Start typing to search...' })
  }

  render() {
    const { menuDataForService, fetching, defaultValueToSet } = this.state;
    const windowURL = "/window/list/:id";
    const menuList = menuDataForService.map(menu => {
      const SortChildMenus = menu.children.sort(function (a, b) {
        return a.seqno - b.seqno
      })

      if (menu.children.length === 0) {
        return (
          <OptGroup label={<span style={{ fontSize: '13px', color: 'black' }}><strong>{menu.title}</strong></span>}>
            <Option
              key={menu.id}
              value={menu.title}
              onClick={() => this.toSendRequest1(menu.id, menu.title)}
            >
              <Link to={windowURL}>
                <span style={{ color: '#314659' }}>{menu.title}</span>
              </Link>
            </Option>
          </OptGroup>
        )
      } else {
        return (
          <OptGroup label={<span style={{ fontSize: '13px', color: 'black' }}><strong>{menu.title}</strong></span>}>
            {SortChildMenus.map(sub => {
              return (
                <Option
                  key={sub.id}
                  value={sub.title}
                  onClick={() => this.toSendRequest1(sub.id, sub.title)}
                >
                  <Link to={windowURL}>
                    <span style={{ color: '#314659' }}>{sub.title}</span>
                  </Link>
                </Option>
              )

            })}
          </OptGroup>
        )
      }

    })
    return (
      <div>
        <Select
          showSearch
          value={<span><span><i className={`${styles.searchIcon} fa fa-search`} /></span><span className={styles.searchBarStyleValue}>{defaultValueToSet}</span></span>}
          allowClear={false}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          showArrow={false}
          style={{ width: 270, boxShadow: '0px 4px 6px -2px #BCBCCB', borderRadius: '5px' }}

        >
          {menuList}
        </Select>
      </div>
    )
  }
}


export default withRouter(Search)


