/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Menu, Dropdown, Icon } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { URL } from '../../../../pages/app-config/index'
import { changeSelectedOption } from '../../../../redux/menu/actions'
import styles from '../../../../customcss/style.module.scss'

const windowURL = "/window/list/:id";
const mapStateToProps = ({ menu }) => ({
  menuData: menu.menuData,
  activeItem: '',

})

@connect(mapStateToProps)
class MenuPages extends React.Component {
  state = {
    menuDataForService: []
  }

  componentWillMount() {

    const newToken = localStorage.getItem('Token')
    const RoleId = localStorage.getItem('roleId')
    const client = new ApolloClient({
      uri: URL,
      request: operation => {
        operation.setContext({
          headers: {
            authorization: `bearer ${newToken}`,
          },
        });
      },
    });

    const GET_MENUS = gql`query { 
       getMenuList(ad_role_id:"${RoleId}")
   }`;

    client
      .query({
        query: GET_MENUS,
      })
      .then(result => {
        const getMenu = JSON.parse(result.data.getMenuList);

        const SortMenus = getMenu["children"].sort(function (a, b) {
          return a.seqno - b.seqno
        })
        this.setState({ menuDataForService: SortMenus })

      }).catch(err => {
        const error = JSON.parse(JSON.stringify(err));
        const { message } = error;
        if(message === "Network error: Unexpected token < in JSON at position 0"){
          const { history } = this.props
          history.push('/system/login')
        }
    })
  }

  toSendRequest = (windowid) => {
    window.localStorage.setItem('adWinId', windowid);
    const { dispatch,history } = this.props;
    if (windowid) dispatch(changeSelectedOption({ selectedOption: windowid }))
    else console.log(".")
    history.push('/window/list/'.concat(`${windowid}`))
  }

  menu = () => {
    const { menuDataForService } = this.state;

    return (
      <Menu selectable={false} mode="inline" style={{ width: 250 }}>
        {menuDataForService.map(item => {
           
          if (item.children.length !== 0) {
           
           const SortChildMenus = item.children.sort(function (a, b) {
            return a.seqno - b.seqno
          })
            return (
              <Menu.SubMenu
                title={
                  <span>
                    {item.title}
                  </span>
                }
                key={item.key}
              >
                {SortChildMenus.map(subItem => {
                 
                  return (
                    <Menu.Item key={subItem.key}>
                      <Link  onClick={() => this.toSendRequest(subItem.id, subItem.key)}>
                        <span
                         
                          role="presentation"
                          key={subItem.key}
                        >
                          {subItem.title}
                        </span>
                      </Link>
                    </Menu.Item>
                  )
                })}
              </Menu.SubMenu>
            )
          }

          if (item.children.length === 0) {

            return (
              <Menu.Item key={item.key}>
                <Link onClick={() => this.toSendRequest(item.id, item.key)}> 
                  <span
                    
                    role="presentation"
                    key={item.key}
                  >
                    {item.title}
                  </span>
                </Link>
              </Menu.Item>
            )
          }

          return null
        })}
      </Menu>
    )
  }

  render() {


    return (

      <Dropdown overlay={this.menu} trigger={['click']} placement="bottomLeft">
        <span
          role="presentation"
          className={`${styles.menuTextStyle} ant-dropdown-link`}
        >
          Menu
          <Icon type="down" />
        </span>
      </Dropdown>
    )
  }
}

export default withRouter(MenuPages)


