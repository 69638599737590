const actions = {
  SET_STATE: 'menu/SET_STATE',
  GET_DATA: 'menu/GET_DATA',
  SELECTED_OPTION: 'SELECTED_OPTION',
  REMOVE_SEARCH_BAR: 'REMOVE_SEARCH_BAR',
  STORE_LINEDATA_FROM_BTNS: 'STORE_LINEDATA_FROM_BTNS',
  SAVE_AUTO_DATA_DISPATCH: 'SAVE_AUTO_DATA_DISPATCH',


}

export const changeSelectedOption = payload => {
  return {
    type: actions.SELECTED_OPTION,
    payload,
  }
}

export const changeAutoSearchBar = payload => {
  return {
    type: actions.REMOVE_SEARCH_BAR,
    payload,
  }
}


export const storeLineDataForUpdateAndCreate = payload => {
  return {
    type: actions.STORE_LINEDATA_FROM_BTNS,
    payload,
  }
}

export const autoCompleteDispacth = payload => {
  return {
    type: actions.SAVE_AUTO_DATA_DISPATCH,
    payload,
  }
}

export default actions
