import React from 'react'
import { withRouter } from 'react-router-dom';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { history } from 'index'
import Search from './Search'
import MenuPages from '../TopBarDark/MenuPages'
import UserMenu from './UserMenu'
import { URL } from '../../../pages/app-config/index'

import style from './style.module.scss'

class TopBar extends React.Component {

  logoOnclick = () => {
    history.push('/CW/home')
  }

  componentDidMount =()=>{
    this.getMessages();
  }

  getMessages = () => {
    const reduxToken = localStorage.getItem('Token')
    const client = new ApolloClient({
      uri: URL,
      request: operation => {
        operation.setContext({
          headers: {
            authorization: `bearer ${reduxToken}`,
          },
        });
      },
    });

    const GET_MESSAGES = gql`query{getMessages}`;
    client
      .query({
        query: GET_MESSAGES,
      })
      .then(result => {
        window.localStorage.setItem('AllMessages', result.data.getMessages);
      }).catch(err => {

        const error = JSON.parse(JSON.stringify(err));
        const { message } = error;
        if (message === "Network error: Unexpected token < in JSON at position 0") {
          history.push('/system/login')
          //  window.location.reload();
  
        }
      })
  }

  render() {
    return (
      <div className={style.topbar}>
        <div className="mr-md-4 mr-auto">
          <img src="resources/images/cw_logo.png" alt="CW ERP" className={style.LogoStyle} onClick={this.logoOnclick} onKeyDown={this.handleKeyDown} role="presentation" />
        </div>
        <div className="mb-0 d-xl-block d-none" style={{marginRight:'37px'}} />
        
        <div className="mr-md-4 mr-auto">
          <MenuPages />
        </div>
        <div className="mr-md-4 mr-auto">
          <Search />
        </div>
        <div className="mr-auto d-none d-md-block" />
        <div className="mb-0 mr-4 d-xl-block d-none" />
        <div className="mr-4 d-none d-sm-block" />
        <div className="mr-4 d-none d-sm-block" />
        <div className="">
          <i className="fa fa-cog" style={{ fontSize: '25px', padding: '10px',color:'#93918F' }} />
        </div>
        <div className="">
          <UserMenu />
        </div>
      </div>
    )
  }
}

export default withRouter(TopBar)
