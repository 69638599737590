import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Menu, Dropdown } from 'antd'
import styles from './style.module.scss'

@connect(({ user }) => ({ user }))
class ProfileMenu extends React.Component {
  state = {
    count: 7,
  }

  logout = e => {
    e.preventDefault()
    const { dispatch } = this.props
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  addCount = () => {
    let { count } = this.state
    count += 1
    this.setState({
      count,
    })
  }

  render() {
    const { user } = this.props;
    const Email=  window.localStorage.getItem('email');
    const RoleName=  window.localStorage.getItem('roleName');
    const PhoneNo=  window.localStorage.getItem('PhoneNo');
    const UserName=   window.localStorage.getItem('User');
    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <strong>
            <FormattedMessage id="topBar.profileMenu.hello" />, {UserName}
          </strong>
          {/* <div>
            <strong className="mr-1">
              <FormattedMessage id="topBar.profileMenu.billingPlan" />:{' '}
            </strong>
            Enterprise@CW
          </div> */}
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.role" />:{' '}
            </strong>
            {RoleName}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.email" />:{' '}
            </strong>
            {Email}
            <br />
            <strong>
              <FormattedMessage id="topBar.profileMenu.phone" />:{' '}
            </strong>
            {PhoneNo || '— '}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="#" onClick={e => e.preventDefault()}>
            <i className={`${styles.menuIcon} fe fe-user`} />
            <FormattedMessage id="topBar.profileMenu.editProfile" />
          </a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="#" onClick={this.logout}>
            <i className={`${styles.menuIcon} fe fe-log-out`} />
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          <strong><span>{user.name}</span></strong><i className="fa fa-user-circle" style={{fontSize:'30px',color:'#706E6B'}}> <i className={`${styles.downArrowStyle} fa fa-sort-down`} /></i>
        </div>
      </Dropdown>
    )
  }
}

export default ProfileMenu
