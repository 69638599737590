import actions from './actions'

const initialState = {
  menuData: [],
  selectedOption: null,
  removeAutoSearchBar:false,
  lineDataFromRenderBtns:[],
  lineDataFromRenderUpdate:[],
  // lineDataFromRenderBtns1:[],
  lineDataFromRenderDelete:[],
  cancelRowDataFromRender:false,
  autoCompleteDataForGrid:[],

}

export default function userReducer(state = initialState, action) {

  if(action.type===actions.SET_STATE){
    return { ...state, ...action.payload }
  }
  if(action.type===actions.SELECTED_OPTION){
    return { ...state, ...action.payload }
  }

  if(action.type===actions.SAVE_AUTO_DATA_DISPATCH){
    console.log(action.payload,"action.payload======>>555560@555560@")
   // return { ...state, ...action.payload }
  }

  if(action.type==="REMOVE_SEARCH_BAR"){
   return { ...state, removeAutoSearchBar:action.payload.removeAutoSearchBar }
  }
  if(action.type==="LINE_DATA_FOR_DISPATCH"){
  
    return { ...state, lineDataFromRenderBtns:action.payload }
   }

   if(action.type==="LINE_DATA_FOR_DELETE"){
  
    return { ...state, lineDataFromRenderDelete:action.payload }
   }

   if(action.type==="LINE_DATA_FOR_UPDATE"){
  
    return { ...state, lineDataFromRenderUpdate:action.payload }
   }

    if(action.type==="REMOVE_PREVIOUS_DATA"){
   
    return { ...state,cancelRowDataFromRender:false, lineDataFromRenderDelete:[], lineDataFromRenderBtns:[],lineDataFromRenderUpdate:[]}
   } 

   if(action.type==="CANCEL_ROW_DATA"){
   
    return { ...state,cancelRowDataFromRender:action.payload}
   } 

   
   if(action.type==="AUTOCOMPLETE_DATA_FOR_GRID"){

    return { ...state,autoCompleteDataForGrid:action.payload}

   } 
   

  return state
}



